// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-certifications-js": () => import("../src/pages/certifications.js" /* webpackChunkName: "component---src-pages-certifications-js" */),
  "component---src-pages-couverture-js": () => import("../src/pages/couverture.js" /* webpackChunkName: "component---src-pages-couverture-js" */),
  "component---src-pages-couverture-chantier-1-js": () => import("../src/pages/couverture/chantier1.js" /* webpackChunkName: "component---src-pages-couverture-chantier-1-js" */),
  "component---src-pages-devis-js": () => import("../src/pages/devis.js" /* webpackChunkName: "component---src-pages-devis-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-isolation-js": () => import("../src/pages/isolation.js" /* webpackChunkName: "component---src-pages-isolation-js" */),
  "component---src-pages-maconnerie-js": () => import("../src/pages/maconnerie.js" /* webpackChunkName: "component---src-pages-maconnerie-js" */),
  "component---src-pages-mentions-legales-js": () => import("../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-menuiserie-js": () => import("../src/pages/menuiserie.js" /* webpackChunkName: "component---src-pages-menuiserie-js" */),
  "component---src-pages-peinture-platerie-js": () => import("../src/pages/peinture-platerie.js" /* webpackChunkName: "component---src-pages-peinture-platerie-js" */),
  "component---src-pages-politique-de-confidentialite-js": () => import("../src/pages/politique-de-confidentialite.js" /* webpackChunkName: "component---src-pages-politique-de-confidentialite-js" */),
  "component---src-pages-renovation-js": () => import("../src/pages/renovation.js" /* webpackChunkName: "component---src-pages-renovation-js" */)
}

